<template>
  <div>
    <b-row>
      <template v-for="(page, ix) in pagesOptions">
        <b-col
          :key="`testPage-${ix}`"
          cols="12"
          lg="4"
        >
          <b-card>
            <template #header>
              <div class="d-flex w-100 justify-content-between">
                <div>
                  <h4 class="mb-0">
                    {{ page.collectionName }}
                  </h4>
                  <h5 class="mb-0">
                    {{ page.companyName }}
                  </h5>
                  <h6 class="mb-0">
                    {{ page.merchantId }}
                  </h6>
                </div>
                <div>
                  <b-button
                    :disabled="IS_SANDBOX || IS_DEVELOPMENT || IS_STAGING"
                    @click="openPage(page.url)"
                  >
                    Open Page
                  </b-button>
                </div>
              </div>
            </template>

            <p>
              Amount: {{ page.amount }}
            </p>
            <b-alert
              show
              class="p-1"
              variant="info"
            >
              {{ page.notes }}
            </b-alert>
            <h5 class=" mt-3">
              <strong>Available Channels</strong>
            </h5>
            <div class="d-flex flex-wrap">
              <template v-for="(ch,idx) in page.channels">
                <div
                  :key="`ch-${idx}`"
                  class="px-1 text-center"
                >
                  <b-img
                    :src="`/img/${ch.name}.png`"
                    height="15"
                    class="m-1"
                  />
                  <br>
                  <small class="text-center">
                    {{ ch.label }}
                  </small>
                </div>
              </template>
            </div>
          </b-card>
        </b-col>
      </template>
    </b-row>

  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
    pagesOptions() {
      return [
        {
          collectionName: 'Cloudeo Admin Deposit Tester',
          companyName: 'Cloudeo Enterprise',
          merchantId: 'LP-9290CC13-MM',
          url: 'https://payment.leanx.io/collection?id=Dc-C1E94BC2A9-Lx',
          amount: 'Open',
          notes: 'This test collection is best for testing FPX and DuitNow for amount more than RM1.00',
          channels: [
            { name: 'WEB_PAYMENT', label: 'FPX' },
            { name: 'GLOBAL_CARD_PAYMENT', label: 'Credit Card' },
            { name: 'DIGITAL_PAYMENT', label: 'E-Wallets' },
            { name: 'BUY_NOW_PAY_LATER', label: 'BNPL' },
            { name: 'DUITNOW_ONLINE_BANKING', label: 'DuitNow' },
            { name: 'QR_PAYMENT', label: 'DuitNow QR' },
          ],
        },
        {
          collectionName: 'Common Collection',
          companyName: 'Azrul Anuar',
          merchantId: 'LP-16E78320-MM',
          url: 'https://payment.leanx.io/collection?id=CL-4FAEE03F79-LNP',
          amount: 'MYR 1.00',
          notes: 'This test collection is best for testing FPX and DuitNow',
          channels: [
            { name: 'WEB_PAYMENT', label: 'FPX' },
            { name: 'GLOBAL_CARD_PAYMENT', label: 'Credit Card' },
            { name: 'DIGITAL_PAYMENT', label: 'E-Wallets' },
            { name: 'BUY_NOW_PAY_LATER', label: 'BNPL' },
            { name: 'DUITNOW_ONLINE_BANKING', label: 'DuitNow' },
            { name: 'QR_PAYMENT', label: 'DuitNow QR' },
          ],
        },
        {
          collectionName: 'e-Jariah Campaign Masjid Wilayah',
          companyName: 'Masjid Wilayah Persekutuan',
          merchantId: 'LP-7E09AC87-MM',
          url: 'https://payment.leanx.io/collection?id=PC-86882646F7-LX',
          amount: 'Open',
          notes: 'The payment options on the page is available only when certain total amount is reached.',
          channels: [
            { name: 'WEB_PAYMENT', label: 'FPX' },
            { name: 'GLOBAL_CARD_PAYMENT', label: 'Credit Card' },
            { name: 'DIGITAL_PAYMENT', label: 'E-Wallets' },
          ],
        },
      ]
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Helper' },
      { title: 'Deposit Tester' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    openPage(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style>

</style>
